import React, {useState, useEffect, useRef} from 'react';
import Signout from './Signout';
import { db, auth } from '../firebase';
import SendMessage from './SendMessage';
import '../App.css';

function Chat(){
    const scroll = useRef()
    const [messages, setMessages] = useState([])
    useEffect(() => {
        db.collection('messages').orderBy('createdAt').limit(50).onSnapshot((snapshot) => {
            setMessages(snapshot.docs.map(doc => doc.data()))
        })
    }, [])
    return(
        
        <div>
           <Signout></Signout>
           <div className="msgs" style={{backgroundImage: "url(/image/chats.jpg)", width: '100%'}}>
            {messages.map(({ id, text, photoURL, uid }) => (
                <div>
                    <div key={id} className={`msg ${uid === auth.currentUser.uid ? 'sent' : 'received'}`}>
                        <img src={photoURL} alt="" />
                        <p> {text} </p>
                    </div>
                </div>
            ))}
            </div>
            <SendMessage scroll={scroll} />
            <div ref={scroll}></div>
        </div>
    )
}

export default Chat;